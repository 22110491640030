import { Role } from '../../auth/models/role.model';
import { CompanyActivity } from '../../models/company-activity.model';
import { Action } from './action.model';
import { Module } from './module.model';

export class Permission {
  company_activity: CompanyActivity;
  module: Module;

  role: Role;

  action: Action;

  group: {
    id: number
  };

  group_module_action: {
    id: number
  };
}
